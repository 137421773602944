import "./src/styles/global.css";
import "@fontsource/roboto"; // デフォルトではウェイト400が読まれる
import "@fontsource/noto-sans-jp";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/noto-sans-jp/400.css";
import "@fontsource/noto-sans-jp/700.css";
import '@sweetalert2/theme-material-ui/material-ui.css';

import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { wrapPageElement } from "./gatsby-ssr"; // 既存の wrapPageElement をインポート
import { navigate } from 'gatsby';

// 認証後のリダイレクト用コールバック関数
const onRedirectCallback = (appState) => {
    //   window.history.replaceState(
    //     {},
    //     document.title,
    //     appState?.returnTo || window.location.pathname
    //   );
    navigate(appState?.returnTo || '/account', { replace: true });
};

// Auth0Providerでアプリ全体をラップ
export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: typeof window !== "undefined" ? window.location.origin : ""
    }}
    useRefreshTokens={true} // リフレッシュトークンを有効化（トークンの更新時のタイムアウト防止）
    onRedirectCallback={onRedirectCallback}
    onLogoutRedirectUri={typeof window !== "undefined" ? window.location.origin : ""}
    >
    {wrapPageElement({ element })} {/* 既存のラップを保持 */}
  </Auth0Provider>
);

// クライアントサイドのみで実行
export const onInitialClientRender = () => {
  setTimeout(() => {
    const loader = document.getElementById("___loader");
    if (loader) {
      loader.style.display = "none";
    }
  }, 500);
};